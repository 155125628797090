import "./bootstrap";
import "../css/front.css";
import { router } from "@inertiajs/vue3";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from 'ziggy-js';

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Autodonor";

// router.on("navigate", (event) => {
//     const re = new RegExp("admin");
//     if (re.test(event.detail.page.url)) {
//     } else {
//         gtag("event", "page_view", {
//             page_location: event.detail.page.url,
//         });
//         gtag("js", new Date());
//         gtag("config", "G-SGDQ3K4QNL");
//     }
// });
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/Front/${name}.vue`,
            import.meta.glob("./Pages/Front/**/*.vue")
        ),
    progress: {
        color: "#29d",
    },
    setup({ el, App, props, plugin }) {
        return (
            createApp({ render: () => h(App, props) })
                .use(plugin)
                //.use(VueApexCharts)
                .use(ZiggyVue)
                .mount(el)
        );
    },
});
